import revive_payload_client_4sVQNw7RlN from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/ugadmin/UGFundWebSite/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ugadmin/UGFundWebSite/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_IJZcPvBsSf from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_client_LcKgStRyi6 from "/home/ugadmin/UGFundWebSite/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/home/ugadmin/UGFundWebSite/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/ugadmin/UGFundWebSite/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auto_logout_PMyNiOAauv from "/home/ugadmin/UGFundWebSite/frontend/plugins/auto-logout.ts";
import chunk_reload_client_BG2S3BHLQY from "/home/ugadmin/UGFundWebSite/frontend/plugins/chunk-reload.client.ts";
import event_tracker_or1ICjq25l from "/home/ugadmin/UGFundWebSite/frontend/plugins/event-tracker.ts";
import page_permissions_vSrIFEBIq8 from "/home/ugadmin/UGFundWebSite/frontend/plugins/page-permissions.ts";
import pinia_shared_state_MMy7vjuo4c from "/home/ugadmin/UGFundWebSite/frontend/plugins/pinia-shared-state.ts";
import sentry_client_shVUlIjFLk from "/home/ugadmin/UGFundWebSite/frontend/plugins/sentry.client.ts";
import swiper_xwaaUO4rVf from "/home/ugadmin/UGFundWebSite/frontend/plugins/swiper.js";
import vue_apexcharts_client_DomB4Mlebn from "/home/ugadmin/UGFundWebSite/frontend/plugins/vue-apexcharts.client.js";
import vue_datepicker_65xHnOJJH3 from "/home/ugadmin/UGFundWebSite/frontend/plugins/vue-datepicker.js";
import vue_quill_client_LiCxsls3Qd from "/home/ugadmin/UGFundWebSite/frontend/plugins/vue-quill.client.js";
import vue3_easy_data_table_SMa4rXzq8q from "/home/ugadmin/UGFundWebSite/frontend/plugins/vue3-easy-data-table.js";
import vue3_lottie_CnZ6P0rItp from "/home/ugadmin/UGFundWebSite/frontend/plugins/vue3-lottie.ts";
import vuetify_8NhHJigKc1 from "/home/ugadmin/UGFundWebSite/frontend/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_IJZcPvBsSf,
  plugin_client_LcKgStRyi6,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  auto_logout_PMyNiOAauv,
  chunk_reload_client_BG2S3BHLQY,
  event_tracker_or1ICjq25l,
  page_permissions_vSrIFEBIq8,
  pinia_shared_state_MMy7vjuo4c,
  sentry_client_shVUlIjFLk,
  swiper_xwaaUO4rVf,
  vue_apexcharts_client_DomB4Mlebn,
  vue_datepicker_65xHnOJJH3,
  vue_quill_client_LiCxsls3Qd,
  vue3_easy_data_table_SMa4rXzq8q,
  vue3_lottie_CnZ6P0rItp,
  vuetify_8NhHJigKc1
]